import React, { Component } from 'react'
import LeftSideBar from "../Components/Common/LeftSideBar";
import Footer from "../Components/Common/Footer";
import TopBar from "../Components/Common/TopBar";
import PageHeader from "../Components/Common/PageHeader";
import functions from "../functions";
import Student from "../Components/Betting/Student";


export class Students extends Component {

    buttons = () => {
        return (
          <div className="btn btn-list">
            
            <a
              className="btn ripple btn-warning"
              href="/add_students"
            >
              <i className="fe fe-plus-square"></i> Add Student
            </a>
    
            
          </div>
        );
      };
  render() {
    return (
        <div className="page">
        
        <LeftSideBar />
        <div
          className="main-content side-content pt-0"
          onClick={functions.closeSystemDrawer}
        >
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Students"
              previousPageTitle="Dashboard"
              back="/"
              buttons={<this.buttons />}
            />
         
          <div className="container">
            <div className="row">
              <Student/>
            </div>
            </div>
            </div>
        <Footer />
      </div>
      </div>
    )
  }
}

export default Students