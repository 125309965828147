import React, { Component } from "react";
import functions from "../../functions";
import AlertSuccess from '../Common/AlertSuccess';
import AlertError from '../Common/AlertError';
import ActivityLoader from '../Common/ActivityLoader';
import ajax from "../../ajax";

export default class Student extends Component {
  state = {
    userId: functions.sessionGuard(),
    students:[],
    loading:false,
    info:'',
    class:null,
    aa:[]
  }
  componentDidMount() { 
    this.listStudents()
    this.listClasses()
   }
   listClasses = async() =>{
    const server_response = await ajax.listClasses(this.state.userId);
    this.setState({aa:server_response.details})
  }
  listStudents = async () =>{
    // this.setState({loading:true, info:''})
    const server_response = await ajax.listStudents(this.state.userId,this.state.class);
    this.setState({loading:false})
    if(server_response.status == 'OK'){
      this.setState({students:server_response.details })
    }else{
      this.setState({info:<AlertError message={server_response.message}/> })
    }

  }
  onChangeClass = (event) => {
    if(event.target.value != '0'){
    this.setState({ class: event.target.value },()=>{
      this.setState({students:[]},()=>{
        this.setState({info:''},()=>{
          this.listStudents()
        })
      })
    });
  }else{
    this.setState({ class: null },()=>{
      this.setState({students:[]},()=>{
        this.setState({info:''},()=>{
          this.listStudents()
        })
      })
    });
  }
  };
  render() {
    const {students} = this.state;
    console.log(students)
    return (
      <div className="col-lg-12 col-sm-12">
        <div className="card custom-card">
          <div className="card-body">
            <div>
              <h4 className="card-header">
                Students
                <div className="float-right">
                <div class="col-md-12">
                <select className='form-control' onChange={this.onChangeClass}> 
                  <option value={'0'}>ALL</option>
                  {
                    this.state.aa && this.state.aa.map((option)=><option key={option.class_id} value={option.class_id}>{option.class_name}</option>)
                  }
                </select>
            </div>
                </div>
              </h4>
             {this.state.info}
             {this.state.loading && <ActivityLoader/>}
            </div>
            <div className="user-manager scroll-widget border-top overflow-auto">
              <div className="table-responsive">
                <table className="table table-lg table-striped table-hover text-nowrap mb-0 mt-3">
                  <thead>
                    <tr>
                      <th>#No</th>
                      <th>Students Name</th>
                      <th>payment Code</th>
                      <th>Class</th>
                      <th>Parents Contact</th>
                      <th>Date Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {students &&
                      Array.isArray(students) &&
                      students.map((clas,key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{clas.student_name}</td>
                          <td>{clas.payment_code}</td>
                          <td>{clas.class.class_name}</td>
                          <td>{clas.parents_contact}</td>
                          <td>{clas.created_at.short_date}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> 
    );
  }
}
