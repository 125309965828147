import React, { Component } from 'react'
import LeftSideBar from "../Components/Common/LeftSideBar";
import Footer from "../Components/Common/Footer";
import TopBar from "../Components/Common/TopBar";
import PageHeader from "../Components/Common/PageHeader";
import functions from "../functions";
import $ from "jquery";
import PopSetClass from '../Components/Betting/PopSetClass';
import TextInput from '../Components/Common/TextInput';
import ajax from '../ajax';
import AlertSuccess from '../Components/Common/AlertSuccess';
import AlertError from '../Components/Common/AlertError';
import dictionary from '../dictionary';
import ActivityLoader from '../Components/Common/ActivityLoader';

export class AddStudents extends Component {
    state = {
        userId: functions.sessionGuard(),
        setClass:'',
        classs:'',
        name:'',
        contact:'',
        classx:'',
        info:'',
        loading:false,
        aa:[]
    }
    componentDidMount() {
      this.listClasses();
     }

      listClasses = async() =>{
        const server_response = await ajax.listClasses(this.state.userId);
        this.setState({aa:server_response.details})
      }
      
       
    buttons = () => {
        return (
          <div className="btn btn-list">
            <a
              className="btn ripple btn-success"
              href="#"
              onClick={this.onClickSetClass}
            >
              <i className="fe fe-plus-square"></i> Set Classes
            </a>
                        <a
              className="btn ripple btn-danger"
              href="/students"
            >
              <i className="fe fe-plus-circle"></i> View Students
            </a>
          </div>
        );
      };
      onClickSetClass = () => {
        this.setState(
          {
            setClass: <PopSetClass />,
          },
          () => {
            $("#modal_set_class").modal("show");
          }
        );
      };
      onChangeClass = (event) => {
        // console.log(event)
        this.setState({ classx: event.target.value });
      };
      onChangeName = (event) => {
        this.setState({ name: event.target.value });
      };
      onChangeContact = (event) => {
        this.setState({ contact: event.target.value });
      };
      onConfirm = () => {
        this.setState(
          {
            info: "",
          },
          async () => {
            const { classx,name,contact,userId } = this.state;
    
            if (classx || name || contact) {
              this.setState({loading:true})
              const server_response = await ajax.registerStudent(userId,classx,name,contact);
              this.setState({loading:false,classx:'',name:'',contact:''})
              if (server_response.status == "OK") {
                this.setState({
                  info: <AlertSuccess message={server_response.message} />,
                });
              } else {
                this.setState({
                  info: <AlertError message={server_response.message} />,
                });
              }
            } else {
              this.setState({
                info: <AlertError message={dictionary._completeFields} />,
              });
            }
          }
        );
      };

  render() {
    // console.log(this.state.classx)
    return (
        <div className="page">
        
        <LeftSideBar />
        <div
          className="main-content side-content pt-0"
          onClick={functions.closeSystemDrawer}
        >
          <TopBar />
          <div className="container-fluid">
              {this.state.setClass}
              
              
            <PageHeader
              title="Add Student"
              previousPageTitle="Dashboard"
              back="/"
              buttons={<this.buttons />}
            />
         
          <div className="container">
            <div className="row">
            <div className="col-lg-12">
            <div className="card custom-card" style={{ borderRadius: 10 }}>
                        <div className="card-body">
                          <div className='card-header mb-4'>
                            <h3 className='text-muted'>Register Student</h3>
                            {this.state.info}
                            {this.state.loading && <ActivityLoader/>}
                          </div>
            <TextInput
                    label="Student Full Names"
                    type="text"
                    onChange={() => this.onChangeName}
                    value={this.state.name}
                  />
            <TextInput
                    label="Parents Contact"
                    type="text"
                    onChange={() => this.onChangeContact}
                    value={this.state.contact}
                  />
           
            
                 <div class="row row-xs align-items-center mg-b-20">
            <div class="col-md-3 text-left">
                <label class="mg-b-0">Select Class</label>
            </div>
            <div class="col-md-9 mg-t-5 mg-md-t-0">
                <select className='form-control' onChange={this.onChangeClass}> 
                  <option>---- select class ----</option>
                  {
                    this.state.aa && this.state.aa.map((option)=><option key={option.class_id} value={option.class_id}>{option.class_name}</option>)
                  }
                </select>
            </div>
            <button onClick={this.onConfirm} style={{width:'100%'}} className='btn btn-success mt-3'>Register Student</button>
        </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
        <Footer />
      </div>
      </div>
    )
  }
}

export default AddStudents