import React from "react";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import ajax from "../../ajax";
import functions from "../../functions";
import $ from "jquery";

class TopBar extends React.Component {
  state = {
    userId: functions.sessionGuard(),
    newRequisition: false,
  };

  componentDidMount() {
    this.closeSideBar();
  }

  toggleSideBar = () => {
    let element = document.getElementsByTagName("body");
    if (window.matchMedia("(min-width: 768px)").matches) {
      element[0].classList.toggle("main-sidebar-hide");
    } else {
      element[0].classList.toggle("main-sidebar-show");
    }
  };

 yyy

  closeSideBar = () => {
    let element = document.getElementsByTagName("body");
    if (window.matchMedia("(min-width: 768px)").matches) {
      // element[0].classList.toggle('main-sidebar-hide');
      element[0].classList.add("main-sidebar-hide");
    } else {
      //element[0].classList.toggle('main-sidebar-show');
      element[0].classList.add("main-sidebar-show");
    }
  };


  logos = () => {
    return (
      <Link className="main-logo d-lg-none" to="/">
        <img
          src={process.env.PUBLIC_URL + "../../images/qp_b.png"}
          className="header-brand-img desktop-logo"
          alt="logo"
        />
        <img
          src={process.env.PUBLIC_URL + "../../images/qp_b.png"}
          className="header-brand-img icon-logo"
          alt="logo"
        />
        <img
          src={process.env.PUBLIC_URL + "../../images/logo.png"}
          className="header-brand-img desktop-logo theme-logo"
          alt="logo"
        />
        <img
          src={process.env.PUBLIC_URL + "../../images/qp_b.png"}
          className="header-brand-img icon-logo theme-logo"
          alt="logo"
        />
      </Link>
    );
  };

  toggleDropdown = (idSelector) => {
    //functions.removeClassFromElements('header-dropdown', 'show')
    setTimeout(() => {
 
        document.getElementById(idSelector).classList.toggle("show");
      
    }, 100);
  };

  onLogout = () => {
    
    window.localStorage.removeItem("quickpost@user");
    window.location.replace("/");
  };

  render() {
    const accessInfo = functions.readPermissions();
    const userInfo = functions.readUser();
    return (
      <>
        {this.state.newRequisition}
        <div className="main-header side-header sticky-pin">
          <div className="container-fluid">
            <div className="main-header-left">
              <this.logos />
              <a
                className="main-header-menu-icon link"
                to="#"
                onClick={this.toggleSideBar}
                id="mainSidebarToggle"
              >
                <span></span>
              </a>
            </div>

            <div className="main-header-center text-center text-light">
              <h3>{userInfo.school_name}</h3>
            </div>
            <div className="main-header-right">
              
              <div
                className="dropdown main-profile-menu header-dropdown"
                id="profile_menu"
              >
                <a
                  className="main-img-user"
                  to="#"
                  onClick={() => this.toggleDropdown("profile_menu")}
                >
                  {/* <img alt="avatar" src={this.state.photo}/> */}
                  <i
                    className="fas fa-user-circle"
                    style={{ fontSize: 28, color: "#FFFFFF" }}
                  ></i>
                </a>
                <div className="dropdown-menu">
                  {accessInfo && (
                    <div className="header-navheading">
                      <h6 className="main-notification-title">
                        {userInfo.school_name}
                      </h6>
                      <p className="main-notification-text">
                        {userInfo.username}
                      </p>
                    </div>
                  )}
                  <a className="dropdown-item border-top" href="#">
                    <i className="fe fe-user"></i> My Account
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                  >
                    <i className="fe fe-edit"></i> New Requisition
                  </a>
                  
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={this.onLogout}
                  >
                    <i className="fe fe-power"></i> Sign Out
                  </Link>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TopBar;
