import React, { Component } from "react";
import functions from "../../functions";
import AlertSuccess from '../Common/AlertSuccess';
import AlertError from '../Common/AlertError';
import ActivityLoader from '../Common/ActivityLoader';
import ajax from "../../ajax";

export default class Classes extends Component {
  state = {
    userId: functions.sessionGuard(),
    classes:[],
    loading:false,
    info:''
  }
  componentDidMount() { 
    this.listClasses()
   }
  listClasses = async () =>{
    this.setState({loading:true})
    const server_response = await ajax.listClasses(this.state.userId);
    this.setState({loading:false})
    if(server_response.status == 'OK'){
      this.setState({classes:server_response.details })
    }else{
      this.setState({info:<AlertError message={server_response.message}/> })
    }

  }
  render() {
    const {classes} = this.state;
    // console.log(classes)
    return (
      <div className="col-lg-6 col-sm-6">
        <div className="card custom-card">
          <div className="card-body">
            <div>
              <h4 className="card-header">
                Classes
              </h4>
             {this.state.info}
             {this.state.loading && <ActivityLoader/>}
            </div>
            <div className="user-manager scroll-widget border-top overflow-auto">
              <div className="table-responsive">
                <table className="table table-striped table-hover text-nowrap mb-0 mt-3">
                  <thead>
                    <tr>
                      <th>#No</th>
                      <th>Class Name</th>
                      <th>Number of students</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {classes &&
                      Array.isArray(classes) &&
                      classes.map((clas,key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{clas.class_name}</td>
                          <td>{clas.number}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
