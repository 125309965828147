import React from "react";
import dictionary from "../../dictionary";
import functions from "../../functions";
import ajax from "../../ajax";
import ActivityLoader from "../Common/ActivityLoader";
import AlertError from "../Common/AlertError";
import TextInput from "../Common/TextInput";
import AlertSuccess from "../Common/AlertSuccess";

class PopSetClass extends React.Component {
  state = {
    userId: functions.sessionGuard(),
    classs: "",
    info: "",
    loading: false,
  };

  onChangeClass = (event) => {
    this.setState({ classs: event.target.value });
  };

  onConfirm = (event) => {
    event.preventDefault();
    this.setState(
      {
        info: "",
      },
      async () => {
        const { classs } = this.state;

        if (classs.length > 0) {
          this.setState({loading:true})
          const server_response = await ajax.setClass(this.state.userId,classs);
          this.setState({loading:false, classs:''})
          if (server_response.status == "OK") {
            this.setState({
              info: <AlertSuccess message={server_response.message} />,
            });
          } else {
            this.setState({
              info: <AlertError message={server_response.message} />,
            });
          }
        } else {
          this.setState({
            info: <AlertError message={dictionary._completeFields} />,
          });
        }
      }
    );
  };

  render() {
    return (
      <>
        <div className="modal" id="modal_set_class">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-content-demo shadow">
              <div className="modal-header">
                <h6 className="modal-title">Set Class</h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.info}
                  <TextInput
                    label="Enter Class"
                    type="text"
                    onChange={() => this.onChangeClass}
                    value={this.state.classs}
                  />

                {/* <p><i>Cash withdrawn fr.</i></p> */}
              </div>
              {!this.state.loading && (
                <div className="modal-footer">
                  <button
                    className="btn ripple btn-success"
                    type="button"
                    onClick={this.onConfirm}
                  >
                    Save Class
                  </button>
                  <button
                    className="btn ripple btn-secondary"
                    type="button"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              )}

              {this.state.loading && (
                <div className="modal-footer">
                  <ActivityLoader />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PopSetClass;
