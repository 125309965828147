import React from "react";
import LeftSideBar from "../../Components/Common/LeftSideBar";
import Footer from "../../Components/Common/Footer";
import TopBar from "../../Components/Common/TopBar";
import PageHeader from "../../Components/Common/PageHeader";
import functions from "../../functions";
import ajax from "../../ajax";
import $ from "jquery";
import Classes from "../../Components/Betting/Classes";
import PopSetClass from "../../Components/Betting/PopSetClass";
import Requests from "../../Components/Betting/Requests";
import Transactions from "../../Components/Betting/Transactions";

class BettingDashboard extends React.Component {
  state = {
    userId: functions.sessionGuard(),
    setClass:''
  };

  componentDidMount() {
    
  }

  

  buttons = () => {
    return (
      <div className="btn btn-list">
        <a
          className="btn ripple btn-success"
          href="#"
          onClick={this.onClickSetClass}
        >
          <i className="fe fe-plus-square"></i> Set Classes
        </a>
        <a
          className="btn ripple btn-warning"
          href="/add_students"
        >
          <i className="fe fe-plus-square"></i> Add Student
        </a>

        <a
          className="btn ripple btn-danger"
          href="/students"
        >
          <i className="fe fe-plus-circle"></i> View Students
        </a>
      </div>
    );
  };

  onClickSetClass = () => {
    this.setState(
      {
        setClass: <PopSetClass />,
      },
      () => {
        $("#modal_set_class").modal("show");
      }
    );
  };

 

  render() {
    
    return (
      <div className="page">
        
        <LeftSideBar />
        <div
          className="main-content side-content pt-0"
          onClick={functions.closeSystemDrawer}
        >
          <TopBar />
          <div className="container-fluid">
            {this.state.setClass}
            <PageHeader
              title="Dashboard"
              previousPageTitle="Dashboard"
              back="/"
              buttons={<this.buttons />}
            />
         
          <div className="container">
            <div className="row">
              <Classes/>
              <Requests/>
              <Transactions/>
            </div>
            </div>
            </div>
        <Footer />
      </div>
      </div>
    );
  }
}

export default BettingDashboard;
