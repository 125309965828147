import React, { Component } from "react";
import functions from "../../functions";
import AlertError from '../Common/AlertError';
import ActivityLoader from '../Common/ActivityLoader';
import ajax from "../../ajax";

export default class Requests extends Component {
  state = {
    userId: functions.sessionGuard(),
    requests:[],
    loading:false,
    info:''
  }
  async componentDidMount() {
    await this.listRequests();
    this.timerActual = setTimeout(
      (this.func1 = async () => {
        await this.listRequests();
        this.timerActual = setTimeout(this.func1, 2000); // (*)
      }),
      2000
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timerActual);
  }
  listRequests = async () =>{
    // this.setState({loading:true})
    const server_response = await ajax.listRequests();
    // console.log(server_response)
    // this.setState({loading:false})
    if(server_response.status == 'OK'){
      this.setState({requests:server_response.details })
    }else{
      this.setState({info:<AlertError message={server_response.message}/> })
    }

  }
  render() {
    const {requests} = this.state;
    // console.log(requests)
    return (
      <div className="col-lg-6 col-sm-6">
        <div className="card custom-card">
          <div className="card-body">
            <div>
              <h4 className="card-header">
                Payment Requests
              </h4>
             {this.state.info}
             {this.state.loading && <ActivityLoader/>}
            </div>
            <div className="user-manager scroll-widget border-top overflow-auto">
              <div className="table-responsive">
                <table className="table table-striped table-hover text-nowrap mb-0 mt-3">
                  <thead>
                    <tr>
                      <th>#No</th>
                      <th>Student</th>
                      <th>Class</th>
                      <th>Phone Number</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Payment Method</th>
                      <th>Date Created</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {requests &&
                      requests.map((clas,key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{clas.student.student_name}</td>
                          <td>{clas.student.class.class_name}</td>
                          <td>{clas.phone_number}</td>
                          <td>{clas.amount}</td>
                          <td>{clas.status == 1 ? <span className="btn btn-primary btn-sm">Pending</span>: clas.status == 2 ?<span className="btn btn-success btn-sm">Finished</span>:<span className="btn btn-danger btn-sm">Failed</span> }</td>
                          <td>{clas.payment_method  == 1 ?"Mobile Money" : "Quick Post"}</td>
                          <td>{clas.date.when}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
