import React, { Component } from "react";
import functions from "../../functions";
import AlertError from '../Common/AlertError';
import ActivityLoader from '../Common/ActivityLoader';
import ajax from "../../ajax";

export default class Transactions extends Component {
  state = {
    userId: functions.sessionGuard(),
    transactions:[],
    loading:false,
    info:''
  }
  async componentDidMount() {
    await this.listTransactions();
    this.timerActual = setTimeout(
      (this.func1 = async () => {
        await this.listTransactions();
        this.timerActual = setTimeout(this.func1, 2000); // (*)
      }),
      2000
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timerActual);
  }
  listTransactions = async () =>{
    // this.setState({loading:true})
    const server_response = await ajax.listTransactions();
    // console.log(server_response)
    // this.setState({loading:false})
    if(server_response.status == 'OK'){
      this.setState({transactions:server_response.details })
    }else{
      this.setState({info:<AlertError message={server_response.message}/> })
    }

  }
  render() {
    const {transactions} = this.state;
    // console.log(Transactions)
    return (
      <div className="col-lg-12 col-sm-12">
        <div className="card custom-card">
          <div className="card-body">
            <div>
              <h4 className="card-header">
                Successfull Transactions
              </h4>
             {this.state.info}
             {this.state.loading && <ActivityLoader/>}
            </div>
            <div className="user-manager scroll-widget border-top overflow-auto">
              <div className="table-responsive">
                <table className="table table-striped table-hover text-nowrap mb-0 mt-3">
                  <thead>
                    <tr>
                      <th>#No</th>
                      <th>Student</th>
                      <th>Class</th>
                      <th>Phone Number</th>
                      <th>Amount</th>
                      <th>Charges</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Payment Method</th>
                      <th>Date Created</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {transactions &&
                      transactions.map((clas,key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{clas.trans.student.student_name}</td>
                          <td>{clas.trans.student.class.class_name}</td>
                          <td>{clas.fund_source}</td>
                          <td>{clas.cash_in}</td>
                          <td>{clas.charges}</td>
                          <td>{clas.description}</td>
                          <td>{clas.trans.status == 1 ? <span className="btn btn-primary btn-sm">Pending</span>: clas.trans.status == 2 ?<span className="btn btn-success btn-sm">Finished</span>:<span className="btn btn-danger btn-sm">Failed</span> }</td>
                          <td>{clas.payment_method}</td>
                          <td>{clas.trans_date}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
