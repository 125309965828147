// const apiHost = "http://localhost/api/qpschools/";
// const apiHost = "https://qppay.masterfoamug.com/";
const apiHost = "https://api.qpschools.araknerd.com/";

export default {
  async loginUser(username, password) {
    let data = {
      username: username,
      password: password,
    };
    try {
      let response = await fetch(apiHost + "school/login", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: false, message:"No internet connection...." };
      return connError;
    }
  },
  async setClass(school,classs) {
    let data = {
      school: school,
      class: classs,
    };
    try {
      let response = await fetch(apiHost + "school/add/class", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: false, message:"No internet connection...." };
      return connError;
    }
  },
  async registerStudent(school,classx,name,contact) {
    let data = {
      school: school,
      class: classx,
      name: name,
      contact: contact,
    };
    try {
      let response = await fetch(apiHost + "school/register/student", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: false, message:"No internet connection...." };
      return connError;
    }
  },
  async securePassword(user_id,password) {
    let data = {
      user_id: user_id,
      password: password,
    };
    try {
      let response = await fetch(apiHost + "school/secure/account", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: false, message:"No internet connection...." };
      return connError;
    }
  },
  async listClasses(user_id) {
    let data = {
      school: user_id,
    };
    try {
      let response = await fetch(apiHost + "school/list/classes", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: false, message:"No internet connection...." };
      return connError;
    }
  },
  async listStudents(user_id,classs) {
    let data = {
      school: user_id,
      class: classs
    };
    try {
      let response = await fetch(apiHost + "school/list/students", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: false, message:"No internet connection...." };
      return connError;
    }
  },
  async listRequests() {
    
    try {
      let response = await fetch(apiHost + "payment/requests", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        }
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: false, message:"No internet connection...." };
      return connError;
    }
  },
  async listTransactions() {
    
    try {
      let response = await fetch(apiHost + "payment/transactions", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        }
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: false, message:"No internet connection...." };
      return connError;
    }
  },
  
};
