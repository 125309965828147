import React from "react";
import AlertError from "../Components/Common/AlertError";
import dictionary from "../dictionary";
import ajax from "../ajax";
import ActivityLoader from "../Components/Common/ActivityLoader";

//const { ipcRenderer } = window.require('electron');

class Login extends React.Component {
  state = {
    username: "",
    password: "",
    error: false,
    info: "",
    loading: false,
  };

  componentDidMount() {
    //ipcRenderer.send('loginScreen')
  }

  onPressLogin = async (event) => {
    event.preventDefault();
    const { username, password } = this.state;
    if (username.length > 0 && password.length > 0) {
      this.setState({
        loading: true,
      });

      const server_response = await ajax.loginUser(username, password);
      if (server_response.status === "OK") {
        //window.location='#dashboard'
        // const cookies = new Cookies();
        //cookies.set('quickpost@user', server_response.details[0].user_id, {expirationDate:0});
        localStorage.setItem(
          "quickpost@user",
          server_response.details["token"]
        );

        this.props.history.push("/");
        window.location.reload();
        // ipcRenderer.send('login', {user:server_response.details[0].user_id})
      } else {
        this.setState({
          info: <AlertError message={server_response.details[0]} />,
          loading: false,
        });
      }
    } else {
      this.setState({
        info: <AlertError message={dictionary._completeFields} />,
        loading: "",
      });
    }
  };

  onChangeUsername = (event) => {
    this.setState({ username: event.target.value });
  };

  onChangePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  render() {
    return (
      <div className="page main-signin-wrapper">
        <div className="row text-center pl-0 pr-0 ml-0 mr-0">
          <div className="col-lg-3 d-block mx-auto">
            <div className="text-center mb-2">
              <img
                src={process.env.PUBLIC_URL + "../../images/qp_b.png"}
                className="header-brand-img"
                alt="logo"
              />
              <img
                src={process.env.PUBLIC_URL + "../../images/qp_b.png"}
                className="header-brand-img theme-logos"
                alt="logo"
              />
            </div>
            <div className="card login-card">
              <div className="card-body">
                <h4 className="text-center">Sign In</h4>
                {this.state.info}
                {this.state.loading && <ActivityLoader />}
                <form method="post" onSubmit={this.onPressLogin}>
                  <div className="form-group text-left">
                    <label>Username</label>
                    <input
                      className="form-control"
                      placeholder="Enter your username"
                      type="text"
                      onChange={this.onChangeUsername}
                    />
                  </div>
                  <div className="form-group text-left">
                    <label>Password</label>
                    <input
                      className="form-control"
                      placeholder="Enter your password"
                      type="password"
                      onChange={this.onChangePassword}
                    />
                  </div>

                  <button className="btn ripple btn-main-primary btn-block">
                    Sign In
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
